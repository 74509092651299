import React, { useEffect, useState } from "react";
import emptyRedMarker from '../resources/img/marker-empty-red.svg';
import emptyGreenMarker from '../resources/img/marker-empty-green.svg';
import { useNotify } from "react-admin";
import { Container } from "@mui/system";
import { Paper } from "@mui/material";
import { useLocation } from "react-router-dom";
import { decode } from "../resources/polyline-decoder";
import { TripPlannerMap } from "./TripPlannerMap";

const HERE_API_KEY = (window.frameElement && window.frameElement.getAttribute("here-map-api-key"))
    ?? (process && process.env && process.env.REACT_APP_HERE_MAP_API_KEY)
    ?? "WhoAreYou?ThisIsABogusKey";

const generateSVG = (type, label) => {
    const baseSVG = (content) => `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">${content}</svg>`;
    const iconContent = {
        start: `<path fill="#FF0000" d="M12 2C8.13 2 5 5.13 5 9c0 4.97 7 13 7 13s7-8.03 7-13c0-3.87-3.13-7-7-7z"/>`,
        point: `<circle cx="12" cy="12" r="8" fill="#2e7dff"/>
                    <text x="12" y="15" font-size="6pt" font-family="Arial" font-weight="bold" text-anchor="middle" fill="white">${label}</text>`
    };
    return baseSVG(iconContent[type]);
};

const BUFFER = 10

// Haversine formula to calculate distance between two coordinates
const getPointsInRange = (lat1, lng1, lat2, lng2) => {
    const toRadians = (degrees) => degrees * (Math.PI / 180);

    const R = 6371; // Radius of the Earth in km
    const deltaLat = toRadians(lat2 - lat1);
    const deltaLng = toRadians(lng2 - lng1);
    const a = Math.sin(deltaLat / 2) ** 2 +
              Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
              Math.sin(deltaLng / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
};

const getBadPoints = (mainPoints, badSectionStart, badSectionEnd) => {
    // Find the start and end indices based on distance
    const startIndex = mainPoints.findIndex(point => 
        getPointsInRange(point[0], point[1], badSectionStart[0], badSectionStart[1]) <= 1.5
    );
    const endIndex = mainPoints.findIndex(point => 
        getPointsInRange(point[0], point[1], badSectionEnd[0], badSectionEnd[1]) <= 1.5
    );

    if (startIndex === -1 || endIndex === -1) {
        return [];
    }

    return mainPoints.slice(startIndex, endIndex + BUFFER);
};


export const HEREMap = () => {
    const notify = useNotify();
    const { state: { geometry, polylines, badChunks, snapShotData } = {} } = useLocation();
    const [pointMarkers, setPointMarkers] = useState([]);
    const [originalPointMarkers, setOriginalPointMarkers] = useState([]);
    const [newPointMarkers, setNewPointMarkers] = useState([]);
    const [isZoomed, setIsZoomed] = useState(false);
    const mapRef = React.createRef();
    let map, behavior = null;
    let startRedmarkers = [];

    const initializeMap = () => {
        if (!mapRef.current) return;
        const platform = new H.service.Platform({ apiKey: HERE_API_KEY });
        const defaultLayers = platform.createDefaultLayers();
        map = new H.Map(mapRef.current, defaultLayers.vector.normal.truck, {
            center: { lat: 39.2011, lng: -98.1285 },
            zoom: 4.6,
            pixelRatio: window.devicePixelRatio || 1
        });
        // add a resize listener to make sure that the map occupies the whole container
        window.addEventListener('resize', () => map.getViewPort().resize());
        // Make the map interactive
        behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        // create default UI with layers provided by the platform
        H.ui.UI.createDefault(map, defaultLayers);
    };

    useEffect(() => {
        if (!snapShotData) {
            try {
                initializeMap();
                if (geometry) drawRouteOnMap(geometry);
            } catch (error) {
                notify(`Error initializing map: ${error}`, { type: 'error' });
            }
        }
    }, [])

    const handleZoom = (bounds, boundZoom, markers) => {
        setIsZoomed(prevIsZoomed => {
            //Toggle zoomed state
            const newIsZoomed = !prevIsZoomed;
            if (newIsZoomed) {
                // Zoom in
                boundZoom
                    ? map.getViewModel().setLookAtData({ bounds: bounds, zoom: 15 })
                    : map.getViewModel().setLookAtData({ bounds: bounds });
                markers.forEach((marker) => marker.setVisibility(true));
            } else {
                // Zoom out
                map.setCenter({ lat: 39.2011, lng: -98.1285 });
                map.setZoom(4.6);
                markers.forEach((marker) => marker.setVisibility(false));
            }
            return newIsZoomed;
        });
    }

    const drawMainRoute = (data) => {
        const mainLineString = new H.geo.LineString();
        data.forEach((point) => { mainLineString.pushLatLngAlt(point.lat, point.lng) });
        const mainPolyline = new H.map.Polyline(mainLineString, { style: { strokeColor: 'blue', lineWidth: 5 } });
        map.addObject(mainPolyline);

        // Add route start marker
        const greenMarkerLocation = { lat: data[0].lat, lng: data[0].lng };
        const greenIcon = new H.map.Icon(emptyGreenMarker, { size: { w: 40, h: 45 } });
        const greenMarker = new H.map.Marker(greenMarkerLocation, { icon: greenIcon });
        map.addObject(greenMarker);

        // Add route end marker
        const redMarkerLocation = { lat: data[data.length - 1].lat, lng: data[data.length - 1].lng };
        const redIcon = new H.map.Icon(emptyRedMarker, { size: { w: 40, h: 45 } });
        const redMarker = new H.map.Marker(redMarkerLocation, { icon: redIcon });
        map.addObject(redMarker);

        // Set view to bounds of the main route.
        map.getViewModel().setLookAtData({ bounds: mainPolyline.getBoundingBox() });
    }

    const drawBadChunks = (badChunks) => {
        // Array of sections case
        badChunks.forEach((section, index) => {
            const redLineString = new H.geo.LineString();
            section.forEach(({ lat, lng }) => redLineString.pushLatLngAlt(lat, lng));

            // Draw red polyline for this section
            const redPolyline = new H.map.Polyline(redLineString, {
                style: { strokeColor: 'red', lineWidth: 3 },
            });
            map.addObject(redPolyline);

            // Add start marker for each section with index number
            const startMarkerLocation = { lat: section[0].lat, lng: section[0].lng };
            const startIconMarkup = generateSVG('start', index + 1);
            const startIcon = new H.map.Icon(startIconMarkup);
            const startMarker = new H.map.Marker(startMarkerLocation, { icon: startIcon });
            map.addObject(startMarker);
            startRedmarkers.push(startMarker);

            section.forEach(({ lat, lng }, index) => {
                const svgMarkup = generateSVG('point', index + 1);
                const sectionIcon = new H.map.Icon(svgMarkup);
                const pointMarker = new H.map.Marker({ lat, lng }, { icon: sectionIcon });
                pointMarker.setVisibility(false);
                map.addObject(pointMarker);
                pointMarkers.push(pointMarker);
            });

            // Toggle zoom in/out on marker click
            startMarker.addEventListener('tap', () => { handleZoom(redPolyline.getBoundingBox(), false, pointMarkers) });
        });
    }

    const drawRouteOnMap = (data) => {
        try {
            // Clear previous map objects to avoid multiple routes displayed.
            map.getObjects().forEach((obj) => { map.removeObject(obj) });
            if (data) {
                drawMainRoute(data);
                if (badChunks) {
                    drawBadChunks(badChunks)
                } else {
                    // Loop through polylines and draw segments (red)
                    for (let i = 0; i < polylines.length - 1; i++) {
                        let sectionMarkers = []
                        const currentLine = decode(polylines[i]).polyline; // Decode current polyline coordinates
                        const nextLine = decode(polylines[i + 1]).polyline; // Decode next polyline coordinates
                        if (currentLine.length > 5) {
                            // Extract last 5 coordinates of currentLine
                            const lastFiveCoords = currentLine.slice(-5);
                            const redLineString = new H.geo.LineString();
                            lastFiveCoords.forEach(([lat, lng]) => {
                                redLineString.pushLatLngAlt(lat, lng);
                            });

                            // Draw red polyline for last 5 coordinates of current segment and the missing ones
                            const redPolyline = new H.map.Polyline(redLineString, {
                                style: { strokeColor: 'red', lineWidth: 3 },
                            });
                            map.addObject(redPolyline);

                            // Add a red marker
                            const sectionPoint = { lat: lastFiveCoords[0][0], lng:lastFiveCoords[0][1] };  // Create H.geo.Point from lat/lng
                            const svgMarkup = generateSVG('start', '')
                            const sectionIcon = new H.map.Icon(svgMarkup);
                            const redMarker = new H.map.Marker(sectionPoint, { icon: sectionIcon });
                            map.addObject(redMarker);
                            startRedmarkers.push(redMarker);
                            const zoomPoint = new H.map.Circle(
                                new H.geo.Point(lastFiveCoords[0][0], lastFiveCoords[0][1]), 
                                0,
                                { style: { fillColor: '#00FFFFFF' } }
                            );
                            map.addObject(zoomPoint) 
                            
                            // Get the missing coordinates between the last of currentLine and the first of nextLine
                            const formattedData = data.map(({ lat, lng }) => [lat, lng]);
                            const missingCoords = getBadPoints(formattedData, lastFiveCoords[4], nextLine[0])

                            const markerIndexMap = new Map();

                            // Create and add draggable point markers
                            const newMarkers = missingCoords.map(([lat, lng], index) => {
                                const svgMarkup = generateSVG('point', index + 1);
                                const sectionIcon = new H.map.Icon(svgMarkup);
                                const pointMarker = new H.map.Marker({ lat, lng }, { icon: sectionIcon });
                                pointMarker.setVisibility(false);
                                pointMarker.draggable = true;
                                pointMarkers.push(pointMarker)

                                //Save the original lat and lng.
                                sectionMarkers.push({ lat: lat, lng: lng })

                                // Handle dragstart event
                                map.addEventListener('dragstart', function (ev) {
                                    let target = ev.target,
                                        pointer = ev.currentPointer;
                                    if (target instanceof H.map.Marker) {
                                        let targetPosition = map.geoToScreen(target.getGeometry());
                                        target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
                                        // Disable map behavior during drag
                                        behavior.disable();
                                    }
                                }, false);

                                // Handle dragend event
                                map.addEventListener('dragend', function (ev) {
                                    let target = ev.target;
                                    if (target instanceof H.map.Marker) {
                                        // Re-enable map behavior after drag
                                        behavior.enable();
                                        // Get the new position of the marker
                                        const newPosition = target.getGeometry();
                                        // Get the stored index of the marker from markerIndexMap
                                        const markerInfo = markerIndexMap.get(target);
                                        if (markerInfo) {
                                            const { sectionIndex, markerIndex } = markerInfo;
                                            // Update the lat/lng in newPointMarkers using the found index
                                            setNewPointMarkers(prevState => {
                                                const updatedState = [...prevState];
                                                updatedState[sectionIndex] = [
                                                    ...updatedState[sectionIndex]
                                                ];
                                                updatedState[sectionIndex][markerIndex] = {
                                                    lat: newPosition.lat,
                                                    lng: newPosition.lng
                                                };
                                                return updatedState;
                                            });
                                        }
                                    }
                                }, false);

                                // Handle drag event to move marker
                                map.addEventListener('drag', function (ev) {
                                    let target = ev.target,
                                        pointer = ev.currentPointer;
                                    if (target instanceof H.map.Marker) {
                                        // Set the marker's new position
                                        target.setGeometry(map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
                                    }
                                }, false);

                                // Add the marker to the map
                                map.addObject(pointMarker);

                                // Store the index in the markerIndexMap
                                markerIndexMap.set(pointMarker, { sectionIndex: newPointMarkers.length, markerIndex: index });

                                // Return lat and lng
                                return { lat, lng };
                            });
                            setNewPointMarkers(prevMarkers => [...prevMarkers, newMarkers]);

                            // Toggle zoom in/out on marker click
                            redMarker.addEventListener('tap', () => { handleZoom(zoomPoint.getBoundingBox(), true, pointMarkers) });
                        }

                        if (nextLine.length > 5) {
                            // Extract first 5 coordinates of nextLine
                            const firstFiveCoords = nextLine.slice(0, 5);
                            const redLineString = new H.geo.LineString();
                            firstFiveCoords.forEach(([lat, lng]) => { redLineString.pushLatLngAlt(lat, lng) });

                            // Draw red polyline for first 5 coordinates of next segment
                            const redPolyline = new H.map.Polyline(redLineString, {
                                style: { strokeColor: 'red', lineWidth: 3 },
                            });
                            map.addObject(redPolyline);
                        }

                        // Add dashed line (red) between the last point of currentLine and first point of nextLine
                        // This will essentially show the problem area of the pluggable section.
                        const dashedLineString = new H.geo.LineString();
                        dashedLineString.pushLatLngAlt(currentLine[currentLine.length - 1][0], currentLine[currentLine.length - 1][1]);
                        dashedLineString.pushLatLngAlt(nextLine[0][0], nextLine[0][1]);
                        const dashedPolyline = new H.map.Polyline(dashedLineString, {
                            style: { strokeColor: 'red', lineWidth: 3, lineDash: [4, 4] }
                        });
                        map.addObject(dashedPolyline);
                        originalPointMarkers.push(sectionMarkers)
                        newPointMarkers.push(sectionMarkers)
                    }

                }
            }
        } catch (error) {
            notify(`Error drawing route on map: ${error}`, { type: error })
        }
    };

    return snapShotData ? (<div style={{margin:'2em'}}><TripPlannerMap snapShotData={snapShotData} /></div>): (
        <div style={{ display: 'flex', marginLeft: '-30px' }}>
            <Container component={Paper}
                id="map-container"
                ref={mapRef}
                style={{
                    width: "100%",
                    height: "40em",
                    overflow: "hidden",
                    touchAction: "none",
                    userSelect: "none",
                    MozUserSelect: "none",
                    WebkitUserSelect: "none",
                    MsUserSelect: "none",
                }}
            />
        </div>
    )
}