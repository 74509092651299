import React, { useState } from "react";
import {
  useRecordContext,
  Loading,
  useGetList,
  useCreate,
  Button,
  useNotify
} from "react-admin";
import DraftsTable from "../components/DraftsTable";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";

export const BackendUserInviteDraftsField = () => {
  const record = useRecordContext();
  const { data, isLoading, error } = useGetList(
    "backend_user_invite_drafts",
    {
      filter: { "event.params.key": `${record.id}` },
      pagination: { page: 1, perPage: 10 }
    }
  );

  if (isLoading) { return <Loading /> }
  if (error) { return <p>ERROR</p> }

  return (
    <DraftsTable data={data} resource={"backend_user_invite_drafts"} />
  );
};

export const InviteButton = ({ clientKey }) => {
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const notify = useNotify();
  const[create, { isLoading, error}] = useCreate();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFirstName('');
    setLastName('');
    setEmail('');
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; //RegEx for Email Validation
    return emailPattern.test(email);
  }

  const handleSubmit = () => {
    if(!firstName || !lastName || !email) {
      notify('All fields are required', {type: 'warning'});
      return;
    }

    if(!isValidEmail(email)){
      notify('Please enter a valid email address', { type: 'warning'});
      return;
    }

    const first_name = firstName;
    const last_name = lastName;

    create(
      'backend_user_invite_drafts',
      {data: { email, first_name, last_name }, meta: { clientKey }},
      {
        onSuccess: () => {
          notify('User invited successfully', { type: 'success'});
          handleClose();
        },
        onFailure: (error) => {
          notify(`Error: ${error.message}`, { type: 'warning'});
        }
      }
    )
  };
  return(
    <>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleOpen}>
        Invite Backend User
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Invite Backend User</DialogTitle>
        <DialogContent>
          <TextField
            id="fName"
            label="First Name"
            fullWidth
            margin="normal"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            id="lName"
            label="Last Name"
            fullWidth
            margin="normal"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            id="email"
            label="Email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color='primary'>
            Invite User
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}