import React, { useState } from "react";
import {
  useRecordContext,
  Loading,
  useGetList
} from "react-admin";
import DraftsTable from "../components/DraftsTable";

export const BackendUserRoleDraftsField = () => {
  const record = useRecordContext();
  const { data, isLoading, error } = useGetList(
    "backend_user_role_drafts",
    {
      meta: {"email": record.id},
      filter: { "event.params.email": `${record.id}` },
      pagination: { page: 1, perPage: 10 }
    }
  );

  if (isLoading) { return <Loading /> }
  if (error) { return <p>ERROR</p> }

  return (
    <DraftsTable data={data} resource={"backend_user_role_drafts"} />
  );
};