import React from "react";
import {
  useRecordContext,
  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  RadioButtonGroupInput,
  TextInput,
  required,
  NotFound,
  useCreatePath,
  Loading,
  useGetList,
  useNotify,
  useRedirect,
  useGetOne,
  useDataProvider,
  useCreateController
} from "react-admin";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Button as MaterialButton } from "@mui/material";
import { useWatch } from "react-hook-form";
import { useSearchParams, Link as RouterLink } from "react-router-dom";
import DraftsTable from "../components/DraftsTable";

export const MigrateButton = () => {
  const record = useRecordContext();
  const createPath = useCreatePath();
  return (
    <MaterialButton
      id="migrate-button"
      color="primary"
      component={RouterLink}
      to={
        createPath({
          resource: "backend_user_migration_drafts",
          type: "create",
        }) + `?email=${encodeURIComponent(record.id)}`
      }
    >
      <ManageAccountsIcon
        style={{ fontSize: "1.25rem", paddingRight: "0.5rem" }}
      />
      Migrate
    </MaterialButton>
  );
};

export const BackendUserMigrationDraftsField = () => {
  const record = useRecordContext();
  const { data, isLoading, error } = useGetList(
    "backend_user_migration_drafts",
    {
      filter: { "event.path": `/${record.id}/` },
      pagination: { page: 1, perPage: 10 }
    }
  );

  if (isLoading) { return <Loading /> }
  if (error) { return <p>ERROR</p> }

  return (
    <DraftsTable data={data} resource={"backend_user_migration_drafts"} />
  );
};

const KeyOrUUIDInput = () => {
  const account_type = useWatch({ name: "account_type" });

  if (account_type === "client") {
    return (
      <ReferenceInput source="key" reference="clients">
        <AutocompleteInput optionText="key" validate={required()} />
      </ReferenceInput>
    );
  } else if (account_type === "customer") {
    return <TextInput source="uuid" label="UUID" validate={required()} />;
  } else {
    return <></>;
  }
};

export const BackendUserMigrationDraftsCreate = () => {
  const createPath = useCreatePath();
  const dataProvider = useDataProvider();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  
  const { data: userRecord, isPending, error } = useGetOne(
    'backend_users',
    { id: email },
  );

  const roamingAdminHandler = async (data) => {
    if (userRecord?.roles.some(role => role.code === "ROAMING-ADMIN")) {
      const response = prompt(
        "Are you sure you want to migrate this user? This user has ROAMING-ADMIN permissions, they will have access to all Hafgufa Environments.\nType 'yes' or 'y' to continue with migration, otherwise hit the cancel button to quit."
      );
      if (response === "yes" || response === "y") {
        return data;
      }
      else {
        return Promise.reject(new Error('Migration canceled by user'));
      }
    }
   else {
    return data;
   }
  };

  if (email == null) {
    return <NotFound />;
  } else {
    return (
      <Create transform = {roamingAdminHandler}
        title={`Migrate backend user ${email}`}
        redirect={createPath({
          resource: "backend_users",
          id: email,
          type: "show",
        }) + "?success="}
      >
        <SimpleForm>
          <TextInput source="email" defaultValue={email} disabled />
          <RadioButtonGroupInput
            source="account_type"
            label="Account type"
            choices={[
              { id: "client", name: "Client" },
              { id: "customer", name: "Customer" },
            ]}
            defaultValue={null}
          />
          <KeyOrUUIDInput />
        </SimpleForm>
      </Create>
    );
  }
};