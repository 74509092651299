import React from "react";
import { useDataProvider, useNotify } from "react-admin";
import GetAppIcon from '@mui/icons-material/GetApp'
import { IconButton } from "@mui/material";

const TRUCK_PROPERTIES = ["type", "height", "width", "length", "limited_weight", "weigth_per_axle", "trailer_count", "tunnel_category", "hazardous_types", "traffic_rerouting"];
const STOP_PROPERTIES = ["stop_number", "stop_type", "current", "name", "address", "city", "state", "postal_code", "location", "identifiers", "truck", "route_options"];
const LOAD_PROPERTIES = ["id", "display_identifier", "sort", "order_number", "load_status", "load_status_label", "active", "current", "trip_planner_enabled", "route_options", "stops", "consignee", "truck", "shipper"];
const ROUTE_OPTIONS_PROPERTIES = ["avoid_dirt_roads", "avoid_toll_roads", "avoid_tunnels", "hide_total_miles"];

const sanitizeObject = (object, allowedProperties) => {
    Object.keys(object).forEach(key => {
        if (!allowedProperties.includes(key)) delete object[key];
    });
};

const sanitizeStop = (stop) => {
    sanitizeObject(stop, STOP_PROPERTIES);
    if (stop.truck) sanitizeObject(stop.truck, TRUCK_PROPERTIES);
    if (stop.route_options) sanitizeObject(stop.route_options, ROUTE_OPTIONS_PROPERTIES);
};

export const TripRouteDownload = (props) => {
    const notify = useNotify();
    const labelStr = props.label;
    const clientKey = props.clientKey;
    const username = props.username;
    const fileType = props.fileType;
    const loadId = props.loadId;
    const uuid = props.trip_plan_uuid;
    const dataProvider = useDataProvider();
    
    const fetchData = async (errorMessage, notify) => {
        try {
            const { data } = await dataProvider.getOne('trip_plans', {
                filter: { clientKey, username, loadId, uuid },
            });
            return data ? data : null
        } catch (error) {
            notify(errorMessage, {type: 'error'});
        }
        return null;
    };

    /*
     * Fetches and sanitizes load data from a specified URL. 
     * Removes any unwanted fields from the main load object and its related properties (ie. stops, shipper, consignee, truck, and route options). 
     * If load data is successfully retrieved and sanitized, it is returned as a JSON string, wrapped in an array format. 
     * If the data is missing or retrieval fails, returns null.
     */
    const extractLoadJson = async () => {
        const resp = await fetchData('Failed retrieving load data.', notify);
        if (!resp?.load) return null;

        const { load } = resp;
        sanitizeObject(load, LOAD_PROPERTIES);
        // Sanitize stops, shipper, consignee, truck, and route_options
        [load.shipper, ...load.stops, load.consignee].filter(Boolean).forEach(sanitizeStop);
        [load.truck, load.route_options].forEach((item, i) => item && sanitizeObject(item, i ? ROUTE_OPTIONS_PROPERTIES : TRUCK_PROPERTIES));

        return JSON.stringify([load]);
    };

    const retrieveSnapshotJSON = async (isForGpx) => {
        const resp = await fetchData(`Failed retrieving ${isForGpx ? 'GPX' : 'snapshot'} data.`, notify);
        if (!resp) return null;
        return isForGpx ? resp : JSON.stringify(resp);
    };

    const downloadFile = (content, filename, type) => {
        const blob = new Blob([content], { type });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    /*
     * Handles the download of a load's data in either GPX or JSON format.
     * 
     * If the selected file type is 'gpx', the function fetches load data in JSON format, 
     * then extracts and formats the relevant stops and geometry points to create a GPX file.
     *  
     * The GPX content includes waypoints for the first and last stops and a track segment 
     * composed of the geometry points along the trip.
     *
     * If the selected file type is 'snapshot' or 'loadData', the function retrieves the 
     * respective JSON data and saves it.
     * 
     * After generating the appropriate file content, the function triggers a download with a filename 
     * based on the load ID and file type. If data retrieval fails at any point, the function exits without downloading.
     */
    const handleDownloadClick = async () => {
        let content, filename, modifiedJson;
        if (fileType === 'gpx') {
            const loadJson = await retrieveSnapshotJSON(true);
            if (!loadJson) return;

            const geometryPoints = loadJson.trip_plan.stops.flatMap(stop =>
                stop.geometry_segments?.flatMap(segment => segment.geometry || []) || []
            );

            const { shipper, consignee } = loadJson.load || {};
            // Combine stops with shipper and consignee if they exist, then sort by stop_number
            const stopArray = [shipper, ...loadJson.load.stops, consignee].filter(Boolean).sort((a, b) => a.stop_number - b.stop_number);

            const gpxContent =
                `<?xml version="1.0" encoding="UTF-8" standalone="no" ?>
<gpx xmlns="http://www.topografix.com/GPX/1/1" xmlns:gpxx="http://www.garmin.com/xmlschemas/GpxExtensions/v3" xmlns:gpxtpx="http://www.garmin.com/xmlschemas/TrackPointExtension/v1" creator="mapstogpx.com" version="1.1" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd http://www.garmin.com/xmlschemas/GpxExtensions/v3 http://www.garmin.com/xmlschemas/GpxExtensionsv3.xsd http://www.garmin.com/xmlschemas/TrackPointExtension/v1 http://www.garmin.com/xmlschemas/TrackPointExtensionv1.xsd">
<wpt lat="${stopArray[0].location.latitude}" lon="${stopArray[0].location.longitude}">
<name>${stopArray[0].name}</name>
</wpt>
<wpt lat="${stopArray[stopArray.length - 1].location.latitude}" lon="${stopArray[stopArray.length - 1].location.longitude}">
<name>${stopArray[stopArray.length - 1].name}</name>
</wpt>
<trk>
<name>${loadJson.load.order_number}</name>
<trkseg>
${geometryPoints.map((point, index) =>
                    `${'\t'}<trkpt lat="${point.latitude}" lon="${point.longitude}"><name>TP${index}</name></trkpt>`
                ).join('\n\t')}
</trkseg>
</trk>
</gpx>`;
            content = gpxContent;
            filename = `${loadId}.gpx`;
        } else {
            content = fileType === 'snapshot' ? await retrieveSnapshotJSON(false) : await extractLoadJson();
            if (!content) return;
            else if(fileType === 'snapshot'){
                let parsedData = JSON.parse(content);
            
                // Transform geometry for each stop
                parsedData.trip_plan.stops = parsedData.trip_plan.stops.map(stop => {
                    // Transform stop.geometry
                    if (stop.geometry) {
                    stop.geometry = stop.geometry.map(point => ({
                        lat: point.latitude,
                        lng: point.longitude
                    }));
                    }
            
                    // Transform stop.geometry_segments
                    if (stop.geometry_segments) {
                    stop.geometry_segments = stop.geometry_segments.map(segment => {
                        if (segment.geometry) {
                        segment.geometry = segment.geometry.map(point => ({
                            lat: point.latitude,
                            lng: point.longitude
                        }));
                        }
                        return segment;
                    });
                    }
            
                    return stop;
                });
            
                // Convert the modified object back to JSON
                modifiedJson = JSON.stringify(parsedData, null, 2);
            }
            
            filename = `${loadId}_${fileType}.json`;
        }
        downloadFile(modifiedJson || content, filename, fileType === 'gpx' ? 'application/gpx+xml' : 'application/json');
    };


    return (
        <React.Fragment>
            
            <IconButton color="primary" size="small" onClick={handleDownloadClick}>
                <span style={{fontSize:'small'}}>{labelStr}</span>
                <GetAppIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
}