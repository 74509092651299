import React from "react";
import {
    DateField, List, Datagrid, TextField, SimpleShowLayout, Show, useRecordContext, TextInput,
    SimpleForm, SelectInput, required, useInput, Create, useShowController,
    TabbedShowLayout, TabbedShowLayoutTabs, Tab, ReferenceManyField, TopToolbar, usePermissions,
    NumberInput, Button, ReferenceInput, AutocompleteInput, useListContext,
    useRedirect,
    FunctionField
} from "react-admin";
import { renderIfPermissions } from "../api/permissionManager";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Add } from "@mui/icons-material";
import EmptyComponent from "../components/EmptyComponent";
import { ContractStructureCreateDoc, ContractStructureProductCreateDoc } from "../documentation/CreateContractStructureDoc";
import { PriceSheetCreateDoc, PriceSheetItemCreateDoc } from "../documentation/PriceSheetCreateDoc";
import { ProductCreateDoc } from "../documentation/ProductCreateDoc";

export const BillingAccountFilter = [
    <TextInput label="Search by code" source="code" alwaysOn />,
]
export const PriceSheetFilter = [
    <TextInput label="Search by code" source="code" alwaysOn />,
]
export const ContractStructureFilter = [
    <TextInput label="Search by code" source="code" alwaysOn />,
]

export const BillingProductList = () => {
    return (
        <List pagination={false} exporter={false} filters={BillingAccountFilter}>
            <Datagrid rowClick="show" size="medium" bulkActionButtons={false}>
                <TextField source="code" sortable={false} />
                <TextField source="name" sortable={false} />
                <DateField source="created_at" sortable={false} />
                <TextField source="billing_unit" sortable={false} />
                <TextField source="billing_period" sortable={false} />
            </Datagrid>
        </List>
    );
}

const JSONBeautifier = ({ source }) => {
    const record = useRecordContext();
    if (!record) return null;
    let beautifiedJSON = '';
    try {
        beautifiedJSON = JSON.stringify(record[source], null, 2);
    } catch (error) {
        beautifiedJSON = 'Invalid JSON';
    }

    const jsonStyle = {
        backgroundColor: '#f5f5f5',
        border: '2px solid #ddd',
        borderRadius: '1em',
        marginLeft: '1em',
        padding: '1em',
        fontFamily: 'monospace',
        color: '#333',
        width: '50%'
    };

    return (
        <div style={jsonStyle}>
            <pre>{beautifiedJSON}</pre>
        </div>
    );
};
export const BillingProductShow = () => {
    return (
        <Show>
            <SimpleShowLayout spacing={2.5} sx={{ paddingBottom: "40px", paddingTop: "16px" }}>
                <TextField source="code" />
                <TextField source="name" />
                <DateField source="created_at" options={{ timeZone: 'UTC' }} />
                <TextField source="description" />
                <JSONBeautifier source="application_filters" />
                <TextField source="billing_unit" />
                <TextField source="billing_period" />
            </SimpleShowLayout>
        </Show>
    );
};

const JsonInput = ({ source, label, validate }) => {
    const { id, field, fieldState } = useInput({ source, validate });

    const jsonStyle = {
        backgroundColor: '#f5f5f5',
        border: fieldState.error ? '0.25em solid #d32f2f' : '2px solid #ddd',
        borderRadius: '1em',
        padding: '1em',
        marginTop: '1em',
        marginBottom: '1em',
        fontFamily: 'monospace',
        color: fieldState.error ? '#d32f2f' : '#333',
        width: '-webkit-fill-available'
    };
    const invalidMsgStyle = {
        color: '#d32f2f',
        fontSize: '0.75rem',
        fontWeight: '400',
        marginTop: '4px',
        marginRight: '14px',
        marginLeft: '14px'
    }

    return (
        <div style={{ display: 'contents' }}>
            <label htmlFor={id} style={{ color: fieldState.error ? '#d32f2f' : '' }}>
                {label}
            </label>
            {fieldState.error && <span style={invalidMsgStyle}>{fieldState.error.message}</span>}
            <textarea
                id={id}
                variant="outlined"
                rows={10}
                cols={25}
                style={jsonStyle}
                {...field}
            />
        </div>
    );
};

const validateJSON = (value) => {
    if (!value) return undefined;
    try {
        JSON.parse(value);
    } catch (error) {
        return 'Invalid JSON';
    }
    return undefined;
};

export const BillingProductCreate = () => (
    <Create aside={<ProductCreateDoc />}>
        <SimpleForm redirect="show">
            <TextInput fullWidth source="code" label="Product Code" validate={required()} />
            <TextInput fullWidth source="name" label="Product Name" validate={required()} />
            <TextInput fullWidth source="description" validate={required()} />
            <JsonInput source="application_filters" label="Application Filters" validate={validateJSON} />
            <SelectInput fullWidth
                className='billing_unit'
                id="billing_unit"
                source="billing_unit"
                choices={[
                    { id: 'flat_fee', name: 'Flat Fee' },
                    { id: 'per_user', name: 'Per User' },
                    { id: 'per_load', name: 'Per Load' },
                    { id: 'per_usage_count', name: 'Per Usage Count' },
                    { id: 'per_api_call', name: 'Per API Call' },
                    { id: 'per_app', name: 'Per App' }
                ]}
                validate={required()}
            />
            <SelectInput fullWidth
                className='billing_period'
                id="billing_period"
                source="billing_period"
                choices={[
                    { id: 'monthly', name: 'Monthly' },
                    { id: 'annually', name: 'Annually' },
                ]}
                validate={required()}
            />
        </SimpleForm>
    </Create>
);

export const BillingPriceSheetList = () => {
    return (
        <List pagination={false} exporter={false} filters={PriceSheetFilter}>
            <Datagrid rowClick="show" size="medium" bulkActionButtons={false}>
                <TextField source="code" sortable={false} />
                <TextField source="description" sortable={false} />
                <DateField source="created_at" sortable={false} options={{ timeZone: 'UTC' }} />
            </Datagrid>
        </List>
    );
}

const PriceSheetShowActions = ({ id }) => {
    const { permissions } = usePermissions();
    const navigate = useNavigate();
    const { total } = useListContext();

    const handleCreateButtonClick = () => {
        navigate('/price_sheet_items/create', { state: { id } });
    };

    if (total === 0) return null;

    return (
        <TopToolbar>
            {renderIfPermissions(permissions, "billing", "write",
                <>
                    <Button startIcon={<Add />} label="Price Sheet Item" onClick={handleCreateButtonClick} />
                </>
            )}
        </TopToolbar>
    );
};

export const BillingPriceSheetCreate = () => (
    <Create aside={<PriceSheetCreateDoc />}>
        <SimpleForm redirect="show">
            <TextInput source="code" label="Price Sheet Code" validate={required()} />
            <TextInput source="description" validate={required()} />
        </SimpleForm>
    </Create>
);

export const BillingPriceSheetShow = (props) => {
    const { id } = useParams();
    const { record } = useShowController();
    const { permissions } = usePermissions();
    const redirect = useRedirect();

    const navigate = useNavigate();
    const handleCreateButtonClick = () => {
        navigate('/price_sheet_items/create', { state: { id } });
    };

    const handleRedirect = (value) => {
        redirect("show", "products", value)
    };

    return (
        <Show {...props}>
            <TabbedShowLayout
                tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" {...props} />}
                spacing={2}
                style={{ paddingBottom: '16px ' }}
                syncWithLocation={false}
            >
                <Tab label="Price Sheet Information" path="info">
                    <TextField source="code" />
                    <TextField source="description" />
                    <DateField source="created_at" />
                </Tab>
                <Tab label="Items" path="price_sheet_items">
                    <ReferenceManyField label="Price Sheet Items" reference="price_sheet_items" target="code" record={record}>
                        <>
                            {renderIfPermissions(permissions, "billing", "write",
                                <PriceSheetShowActions id={id} />
                            )}
                            <Datagrid
                                bulkActionButtons={false}
                                empty={<EmptyComponent permissionResource="billing" label="Price Sheet Items" onClick={handleCreateButtonClick} permissions={permissions} />}
                            >
                                <FunctionField
                                    label="Product Code"
                                    render={(record) => (
                                        <Button
                                            onClick={() => handleRedirect(record.product_code)}
                                            label={record.product_code}
                                        />
                                    )}
                                />
                                <DateField source="created_at" />
                            </Datagrid>
                        </>
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}

export const PriceSheetItemCreate = () => {
    const location = useLocation();
    const { id } = location.state || {};

    return (
        <Create
            resource="price_sheet_items"
            redirect={`/price_sheets/${id}/show`}
            title="Create Price Sheet Item"
            aside={<PriceSheetItemCreateDoc />}
        >
            <SimpleForm>
                <TextInput source="price_sheet_code" label="Price Sheet Code" defaultValue={id} validate={required()} disabled />
                <ReferenceInput source="product_code" reference='products'>
                    <AutocompleteInput optionText="code" validate={required()} />
                </ReferenceInput>
                <NumberInput source="price_cents" label="Price (in cents)" validate={required()} />
                <NumberInput source="free_quantity" label="Free Quantity" defaultValue={0} validate={required()} />
            </SimpleForm>
        </Create>
    );
};

const ContractStructureShowActions = ({ id }) => {
    const { permissions } = usePermissions();
    const navigate = useNavigate();
    const { total } = useListContext();

    const handleCreateButtonClick = () => {
        navigate('/contract_structure_products/create', { state: { id } });
    };

    if (total === 0) return null;

    return (
        <TopToolbar>
            {renderIfPermissions(permissions, "billing", "write",
                <>
                    <Button startIcon={<Add />} label="Contract Structure Product" onClick={handleCreateButtonClick} />
                </>
            )}
        </TopToolbar>
    );
};

export const BillingContractStructureList = () => {
    return (
        <List pagination={false} exporter={false} filters={ContractStructureFilter}>
            <Datagrid rowClick="show" size="medium" bulkActionButtons={false}>
                <TextField source="code" sortable={false} />
                <TextField source="name" sortable={false} />
                <DateField source="created_at" sortable={false} options={{ timeZone: 'UTC' }} />
            </Datagrid>
        </List>
    );
}

export const BillingContractStructureShow = (props) => {
    const { id } = useParams();
    const { permissions } = usePermissions();
    const navigate = useNavigate();
    const redirect = useRedirect();

    const handleCreateButtonClick = () => {
        navigate('/contract_structure_products/create', { state: { id } });
    };

    const handleRedirect = (value) => {
        redirect("show", "products", value)
    };
    return (
        <Show {...props}>
            <TabbedShowLayout
                tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" {...props} />}
                spacing={2}
                style={{ paddingBottom: '16px ' }}
                syncWithLocation={false}
            >
                <Tab label="Contract Structure Information" path="info">
                    <TextField source="code" sortable={false} />
                    <TextField source="name" sortable={false} />
                    <DateField source="created_at" sortable={false} options={{ timeZone: 'UTC' }} />
                </Tab>
                <Tab label="Structure Products" path="contract_structure_products">
                    <ReferenceManyField label="Contract Structure Products" reference="contract_structure_products" target="product_code" filter={{ id: id }}>
                        <>
                            {renderIfPermissions(permissions, "billing", "write", <ContractStructureShowActions id={id} />)}
                            <Datagrid
                                size="medium"
                                bulkActionButtons={false}
                                empty={<EmptyComponent permissionResource="billing" label="Contract Structure Products" onClick={handleCreateButtonClick} permissions={permissions} />}
                            >
                                <FunctionField
                                    label="Product Code"
                                    render={(record) => (
                                        <Button
                                            onClick={() => handleRedirect(record.product_code)}
                                            label={record.product_code}
                                        />
                                    )}
                                />
                                <DateField source="created_at" sortable={false} options={{ timeZone: 'UTC' }} />
                            </Datagrid>
                        </>
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
}

export const BillingContractStructureCreate = () => (
    <Create aside={<ContractStructureCreateDoc />}>
        <SimpleForm redirect="show">
            <TextInput fullWidth source="code" label="Contract Structure Code" validate={required()} />
            <TextInput fullWidth source="name" label="Contract Structure Name" validate={required()} />
        </SimpleForm>
    </Create>
);

export const ContractStructureProductCreate = () => {
    const location = useLocation();
    const { id } = location.state || {};

    return (
        <Create
            resource="contract_structure_products"
            redirect={`/contract_structures/${id}/show`}
            title="Create Contract Structure Product"
            aside={<ContractStructureProductCreateDoc />}
        >
            <SimpleForm>
                <TextInput source="contract_structure_code" label="Contract Structure Code" defaultValue={id} validate={required()} disabled />
                <ReferenceInput source="product_code" reference='products'>
                    <AutocompleteInput optionText="code" validate={required()} />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};