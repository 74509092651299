import React from "react";
import { styled } from '@mui/system';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Heading = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(15),
  fontWeight: theme.typography.fontWeightRegular,
}));


export default function AppCreateDoc() {
  return(
    <div styles={{ width: '100%'}}>
      <h1>More Info</h1>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Client Key</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>This is the client key belonging to the live client</li>
              <li><b>CANNOT</b> be changed later</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>App Name</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>This is what the App will be called</li>
              <li><b>CANNOT</b> be changed later</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>iOS Bundle Name</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>Found in the HERE dev portal</li>
              <li>Reverse DNS</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>iOS HERE id</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>From HERE dev portal project</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>iOS HERE code</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>From HERE dev portal project</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>iOS HERE key</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>From HERE dev portal project</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Android Package Name</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>From HERE dev portal project</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Android HERE id</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>From HERE dev portal project</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Android HERE code</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>From HERE dev portal project</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Android HERE key</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>From HERE dev portal project</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Android Fingerprints</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>This is the SHA-256 fingerprint for the app signing key that Google uses to sign each release.</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Heading>Genius Scan Keys</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>{"Go to 1Password -> Advanced Operations -> get the Genius Scan SDK credentials"}</li>
              <li>Go to the portal from 1Password</li>
              <li>Scroll down and find and click the "Log in if you already have an account" link</li>
              <li>Login using the credentials</li>
              <li>Click View App at the bottom of the list</li>
              <li>Click Add Bundle Identifier</li>
              <li>Fill out and save the form.
                  <ul>We will be using one key for both Android & iOS bundle IDs in IA</ul>
                  <ul><b>NOTE: Make sure you select "Android & iOS" under OS</b></ul>
              </li>
              <li>Copy the Key. <b>WARNING: Make sure to avoid hitting the Delete button.</b></li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Firebase Project id</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>Found in G suite</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>Apple Team Identifier</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>SHOULD be Eleos unless told otherwise</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
        >
          <Heading>APNS production</Heading>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ul>
              <li>This is for Apple push notifications</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}