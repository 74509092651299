import React, { useState } from "react";
import { useCreate, useDelete, useNotify, useRefresh, useRecordContext } from "react-admin";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Autocomplete, FormControl, Button as MaterialButton, TextField as MaterialTextInput} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete'


const AddRoleButton = (record) => {
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState('');
  const [create] = useCreate();
  const notify = useNotify();
  const navigate = useNavigate();
  const refresh = useRefresh();
  const [isChanged, setIsChanged] = useState(false);

  const handleChange = (event, newValue) => {
    setRole(newValue);
    setIsChanged(true)
  };

  const handleNewRole = () => {
    if (isChanged === false || role === null) {
      notify("No role selected", { type: 'info' })
      return
    }

    const diff = record.record.roles
    diff.push(role)

    create('backend_user_role_drafts', { id: record.record.id, data: diff, previousData: record, meta: {"email": record.record.id} }, {
      onSuccess: () => {
        setOpen(false);
        refresh();
        notify(`Draft to add ${role.code} to user ${record.record.id} created.`, {type:'success'})
      },
      onFailure: error => {
        notify(`Failed to add role ${role.code}: ${error}`, {type:'error'})
      }
    })
    setIsChanged(false)
    navigate(`?modify_role_draft_created`)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const unassignedRoles = () => {
    let all_roles = record?.record.unassigned_roles
    if (typeof record.record.unassigned_roles === 'string') {
      all_roles = JSON.parse(all_roles);
    }
    const unassigned = all_roles.filter(n => record.record.roles.findIndex(m => m.code === n.code) === -1);
    return unassigned;
  }


  return (
    <div>
      <MaterialButton color="primary" variant="outlined" onClick={handleClickOpen}>
        ADD
      </MaterialButton>
      <Dialog open={open} fullwidth maxWidth='lg' onClose={handleClose}>
        <DialogTitle>Add Role</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select one of the following permissions to add to this user.
          </DialogContentText>
          <FormControl variant="standard" fullWidth>
            <Autocomplete
              id="role-box"
              options={unassignedRoles()}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.value == value.value}
              onChange={handleChange}
              sx={{ width: 300 }}
              renderInput={(params) => <MaterialTextInput{...params} variant="standard" label="Role" />}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <MaterialButton onClick={handleClose}>Cancel</MaterialButton>
          <MaterialButton onClick={handleNewRole}>Add Role</MaterialButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const RoleDeleteButton = (record) => {
  const [open, setOpen] = useState(false);
  const [deleteOne] = useDelete();
  const notify = useNotify();
  const refresh = useRefresh();
  const roleData = useRecordContext()
  const handleDeleteRole = (record) => {
    const current_roles = record.record.roles;
    const deleted_role = roleData;
    const diff = current_roles.filter(el => el.code !== deleted_role.code);

    deleteOne('backend_user_role_drafts', { id: record.record.id, previousData: record, meta: {"email": record.record.id, "data": diff} } , {
      onSuccess: () => {
        setOpen(false);
        refresh();
        notify(`Draft to remove ${deleted_role.code} from user ${record.record.id} created`, {type:'success'})
      },
      onFailure: error => {
        notify(`Failed to remove role ${deleted_role.code}: ${error}`, {type:'error'})
      }
    })

  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <MaterialButton onClick={handleClickOpen} style={{color:'#FF7C60'}} ><DeleteIcon/>Remove</MaterialButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Remove This Role?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this role from the user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MaterialButton onClick={handleClose}>Cancel</MaterialButton>
          <MaterialButton aria-label="dialog-remove-role" onClick={e => handleDeleteRole(record)}>Remove</MaterialButton>
        </DialogActions>
      </Dialog>
    </div>

  );
}

export {
  AddRoleButton,
  RoleDeleteButton
}