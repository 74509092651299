import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete'
import React, { useState } from "react";
import {Button as MaterialButton} from '@mui/material';
import { useNotify, useRefresh, useRecordContext, useDelete } from 'react-admin';

const BlockDeleteButton = (record) => {
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const emailData = record['record'];
    const email = useRecordContext().email
    const [deleteOne, {isPending}] = useDelete()
    
    const handleDeleteEmail = async (record) => {
        await deleteOne('sendgrid', {id: email, meta: 'Blocks' }, 
            {
                onSuccess: () => {
                    notify(`Blocks has been removed for ${email}`, {type: "success"});
                    setOpen(false);
                    refresh();
                }, 
                onError: (error) => notify(`Request failed. ${error}`, {type: "error" })
            }
        )
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false)
    };

    let checkBlocks = emailData.map(type => (type.type))
    if(checkBlocks.includes('block')){
        return (
            <div>
                <MaterialButton onClick={handleClickOpen} style={{ color: 'FF7C60'}} ><DeleteIcon/>Delete Blocks</MaterialButton>
                <Dialog open={open}  onClose={handleClose}>
                    <DialogTitle>Delete Blocks?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to remove blocks?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MaterialButton onClick={handleClose}>Cancel</MaterialButton>
                        <MaterialButton aria-label="dialog-remove-email" onClick={e => handleDeleteEmail(record)}>Delete Blocks</MaterialButton>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

const BounceDeleteButton = (record) => {
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const email = useRecordContext().email;
    const emailData = record['record'];
    const [deleteOne, {isPending}] = useDelete()
    
    const handleDeleteEmail = async (record) => {
        await deleteOne('sendgrid', {id: email, meta: 'Bounce' }, 
            {
                onSuccess: () => {
                    notify(`Bounces has been removed for ${email}`, {type: "success"});
                    setOpen(false);
                    refresh();
                }, 
                onError: (error) => notify(`Request failed. ${error}`, {type: "error" })
            }
        )
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false)
    };

    let checkBounces = emailData.map(type => (type.type))
    if(checkBounces.includes('bounce')){
        return (
            <div>
                <MaterialButton onClick={handleClickOpen} style={{ color: 'FF7C60'}} ><DeleteIcon/>Delete Bounces</MaterialButton>
                <Dialog open={open}  onClose={handleClose}>
                    <DialogTitle>Delete Bounces?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to remove bounces?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MaterialButton onClick={handleClose}>Cancel</MaterialButton>
                        <MaterialButton aria-label="dialog-remove-email" onClick={e => handleDeleteEmail(record)}>Delete Bounces</MaterialButton>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
const UnsubscribeDeleteButton = (record) => {
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const email = useRecordContext().email;
    const emailData = record['record']
    const [deleteOne, {isPending}] = useDelete()
    
    const handleDeleteEmail = async (record) => {
        await deleteOne('sendgrid', {id: email, meta: 'Unsubscribe' }, 
            {
                onSuccess: () => {
                    notify(`Unsubscribes has been removed for ${email}`, {type: "success"});
                    setOpen(false);
                    refresh();
                }, 
                onError: (error) => notify(`Request failed. ${error}`, {type: "error" })
            }
        )
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false)
    };

    let checkUnsubscribes = emailData.map(type => (type.type))
    if(checkUnsubscribes.includes('unsubscribe')){
        return (
            <div>
                <MaterialButton onClick={handleClickOpen} style={{ color: 'FF7C60'}} ><DeleteIcon/>Delete Unsubscribes</MaterialButton>
                <Dialog open={open}  onClose={handleClose}>
                    <DialogTitle>Delete Unsubscribes?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to remove unsubscribes?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MaterialButton onClick={handleClose}>Cancel</MaterialButton>
                        <MaterialButton disabled={isPending} aria-label="dialog-remove-email" onClick={e => handleDeleteEmail(record)}>Delete Unsubscribes</MaterialButton>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export {
    BounceDeleteButton,
    BlockDeleteButton,
    UnsubscribeDeleteButton
}
